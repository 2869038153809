import { I_CallToAction } from '@containers/Home/types';
import { memo, useMemo } from 'react';
import { CallToActionItemWrapper } from './styled';

interface Props {
  item: I_CallToAction;
}

const CallToActionItem = memo(({ item }: Props) => {
  const icon = item.typeIcon === 'image' ? item.icon : item.icon?.split('/')[1];

  const targetHref = useMemo(() => {
    switch (item.typeValue) {
      case 'whatsapp':
      case 'viber':
      case 'link':
        return item.value;
      case 'mailto':
        return `mailto:${item.value}`;
      case 'tel':
        return `tel:${item.value}`;
      default:
        return item.value;
    }
  }, [item]);

  return (
    <CallToActionItemWrapper
      as="a"
      target="_blank"
      href={targetHref}
      className="itemLink"
    >
      {item.typeIcon === 'image' ? (
        <img className="--symbol" src={icon} alt={item?.title} />
      ) : (
        <i aria-hidden className={`--symbol ${icon}`} />
      )}
      <span>{item.title || ''}</span>
      <i aria-hidden className="--arrow fal fa-angle-right" />
    </CallToActionItemWrapper>
  );
});

export default CallToActionItem;
